<template>
  <div class="chat-list" :style="{ height: containerHeight}">
    <b v-show="pinnedConversations?.length > 0">Pinned</b>
    <div
      v-for="conversation in pinnedConversations"
      :key="conversation.id"
      @click="setSelectedConversation(conversation)"
      :class="{ selected: selectedConversation?.id === conversation?.id }"
      class="flex items-center justify-between p-2 cursor-pointer"
    >
      <div class="flex items-center">
        <div class="relative mr-4">
          <img
            :src="
              conversation.info.avatar == null
                ? 'https://ui-avatars.com/api/?name=' +
                  conversation.info.name.split(' ')[0] +
                  '+' +
                  conversation.info.name.split(' ')[1]
                : conversation.info.avatar
            "
            alt="Avatar"
            class="w-8 h-8 rounded-full"
          />
          <div
            v-if="conversation.info.is_online == 1"
            class="absolute bottom-0 right-0 w-2 h-2 bg-green-500 border border-white rounded-full"
          ></div>
          <div
            v-else
            class="absolute bottom-0 right-0 w-2 h-2 bg-gray-500 border border-white rounded-full"
          ></div>
        </div>
        <div>
          <h1 class="text-md font-lighter">{{ conversation.info.name }}</h1>
          <p
            class="text-sm"
            :class="
              selectedConversation?.id === conversation?.id
                ? 'text-white'
                : 'text-gray-500'
            "
          >
            {{
              conversation?.last_message.slice(0, 30) +
              (conversation?.last_message.length > 30 ? "..." : "")
            }}
          </p>
        </div>
      </div>
      <div class="flex items-center" @click.stop>
        <i
          v-if="conversation.unread_count > 0"
          class="fas fa-circle text-red-500 mr-2"
          style="font-size: 8px"
        ></i>
        <p
          class="text-xs mr-2"
          :class="
            selectedConversation?.id === conversation?.id
              ? 'text-gray-100'
              : 'text-gray-500'
          "
        >
          {{ formattedTime(conversation?.updatedAt) }}
        </p>
        <button class="px-2" @click.stop="toggleMenu(conversation.id)">
          <i
            class="fas fa-ellipsis-v cursor-pointer"
            :class="
              selectedConversation?.id === conversation?.id
                ? 'text-gray-100'
                : 'text-gray-500'
            "
          ></i>
        </button>
        <!-- Menu items -->
        <div
          v-if="showContextMenu && markedConversationId === conversation.id"
          class="relative right-0 top-0 mt-8 bg-white p-4 border border-gray-200 rounded-md p-2"
        >
          <button
            @click="togglePinStatus(conversation.id, !conversation.is_pinned)" style="color:black"
          >
            <i
              :class="
                conversation.is_pinned
                  ? 'fa-solid fa-comment-slash'
                  : 'fas fa-thumbtack'
              "
            ></i>
            {{ conversation.is_pinned ? "Unpin" : "Pin" }}
          </button>
        </div>
      </div>
    </div>
    <b v-show="conversations?.length > 0">Other Messages</b>
    <div
      v-for="conversation in conversations"
      :key="conversation.id"
      @click="setSelectedConversation(conversation)"
      :class="{ selected: selectedConversation?.id === conversation?.id }"
      class="flex items-center justify-between p-2 cursor-pointer"
    >
      <div class="flex items-center">
        <div class="relative mr-4">
          <img
            :src="
              conversation.info.avatar == null
                ? 'https://ui-avatars.com/api/?name=' +
                  conversation.info.name.split(' ')[0] +
                  '+' +
                  conversation.info.name.split(' ')[1]
                : conversation.info.avatar
            "
            alt="Avatar"
            class="w-8 h-8 rounded-full"
          />
          <div
            v-if="conversation.info.is_online == 1"
            class="absolute bottom-0 right-0 w-2 h-2 bg-green-500 border border-white rounded-full"
          ></div>
          <div
            v-else
            class="absolute bottom-0 right-0 w-2 h-2 bg-gray-500 border border-white rounded-full"
          ></div>
        </div>
        <div>
          <h1 class="text-md font-lighter">{{ conversation.info.name }}</h1>
          <p
            class="text-sm"
            :class="
              selectedConversation?.id === conversation?.id
                ? 'text-white'
                : 'text-gray-500'
            "
          >
            {{
              conversation?.last_message.slice(0, 30) +
              (conversation?.last_message.length > 30 ? "..." : "")
            }}
          </p>
        </div>
      </div>
      <div class="flex items-center" @click.stop>
        <i
          v-if="conversation.unread_count > 0"
          class="fas fa-circle text-red-500 mr-2"
          style="font-size: 8px"
        ></i>
        <p
          class="text-xs mr-2"
          :class="
            selectedConversation?.id === conversation?.id
              ? 'text-gray-100'
              : 'text-gray-500'
          "
        >
          {{ formattedTime(conversation?.updatedAt) }}
        </p>
        <button class="px-2" @click="toggleMenu(conversation.id)">
          <i
            class="fas fa-ellipsis-v cursor-pointer"
            :class="
              selectedConversation?.id === conversation?.id
                ? 'text-gray-100'
                : 'text-gray-500'
            "
          ></i>
        </button>
        <!-- Menu items -->
        <div
          v-if="showContextMenu && (markedConversationId === conversation.id)"
          class="relative right-0 top-0 mt-8 bg-white p-4 border border-gray-200 rounded-md p-2"
        >
          <button
            @click="togglePinStatus(conversation.id, !conversation.is_pinned)" style="color: black;"
          >
            <i
              :class="
                conversation.is_pinned
                  ? 'fa-solid fa-comment-slash'
                  : 'fas fa-thumbtack'
              "
            ></i>
            {{ conversation.is_pinned ? "Unpin" : "Pin" }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {
  ref,
  onMounted,
  defineProps,
  computed,
  defineEmits,
  nextTick,
} from "vue";

import store from "@/store";

// const props = defineProps(["close", "container-height"]);

const props = defineProps({
  containerHeight: {
    type: String,
    required: true,
    default: '350px'
  }
});

const emits = defineEmits(["scroll-to-bottom"]);

const showContextMenu = ref(false);
const isConversationLoading = ref(true);
const isOpen = ref(false);
const markedConversationId = ref(0);

// const showMenu = (id) => {
//   markedConversationId.value = id;
//   showContextMenu.value = true;
// };

// const closeMenu = () => {
//   showContextMenu.value = false;
// };

const toggleMenu = (id) => {
  showContextMenu.value = !showContextMenu.value;
  if (showContextMenu.value){
    markedConversationId.value = id;
  } else {
    markedConversationId.value = 0;
  }
};

const togglePinStatus = async (conversationId, status) => {
  await store.dispatch("togglePinStatus", { conversationId, status });
  toggleMenu(conversationId);
};

const conversations = computed(() => {
  return store.getters["conversations"];
});

const pinnedConversations = computed(() => {
  return store.getters["pinnedConversations"];
});

const selectedConversation = computed(() => {
  return store.getters["selectedConversation"];
});

const formattedTime = (createdAt) => {
  const dateObject = new Date(createdAt);
  const hours = dateObject.getHours();
  const minutes = dateObject.getMinutes();

  return `${hours.toString().padStart(2, "0")}:${minutes
    .toString()
    .padStart(2, "0")}`;
};

const setSelectedConversation = async (conversation) => {
  await store.dispatch("setSelectedChatConversation", conversation);
  emits("scroll-to-bottom");
  isConversationLoading.value = false;
};
</script>

<style scoped lang="scss">
.chat-list {
  position: relative;
  padding: 5px;
  background: white;
  height: 350px;
  width: 350px;
  overflow-x: hidden;
  overflow-y: scroll;

  .selected {
    background-color: #ef4444;
    color: #ffffff;
    border-radius: 10px;
  }

  &::-webkit-scrollbar {
    width: 2px;
  }

  .cursor-pointer {
    cursor: pointer;
  }

  .menu-list {
    position: absolute;
    top: 100%;
    right: 0;
    z-index: 999 !important;
    display: none;
    min-width: 120px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    z-index: 999;
  }
}
</style>

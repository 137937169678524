import { createApp } from 'vue'
import App from './views/App.vue'
import router from './router'
import axios from 'axios'
import '@fortawesome/fontawesome-free/css/all.css'
import '/index.css'
import store from './store';
import { createHead } from '@vueuse/head';
import { createPinia } from 'pinia';
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
import socketPlugin from './plugins/socketPlugin'

axios.defaults.baseURL = process.env.VUE_APP_APIURL;

// Request interceptor to set the Authorization header dynamically
axios.interceptors.request.use((config) => {
    const token = localStorage.getItem('token');
    if (token) {
        config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
});

// Setup Axios interceptor for handling unauthenticated responses
axios.interceptors.response.use(response => {
  // Just return the response if it's successful
  return response;
}, error => {
  // Check if the response is a 401 unauthenticated error
  if (error.response && error.response.status === 401) {
    // Redirect to the /logout route
    router.push('/logout');
  }
  return Promise.reject(error);
});

// Setup Vue
const head = createHead();
// const pinia = createPinia();

createApp(App)
  .use(head)
  .use(store)
  .use(router)
  .use(socketPlugin)
  // .use(socketPlugin)
  .component('VueDatePicker', VueDatePicker)
  .mount('#app')

import { io } from 'socket.io-client';

const socketPlugin = {
  install: (app) => {
    const socket = io('wss://portal-ws-specialolympicsarizona-org.staging.aimit.io', {
      transports: ['websocket'],
    });

    const myUserId = localStorage.getItem('user_id') || null;

    app.config.globalProperties.$socket = socket;

    socket.on('connect', () => {
      if (myUserId) {
        socket.on('online', (uId) => {
          console.log('online', uId);
          app.config.globalProperties.$store.dispatch('handleOnlineStatus', { uId, isOnline: true });
        });

        socket.on('offline', (uId) => {
          console.log('offline', uId);
          app.config.globalProperties.$store.dispatch('handleOnlineStatus', { uId, isOnline: false });
        });

        socket.on('receive_message', async (receivableMessage) => {
          app.config.globalProperties.$store.dispatch('receiveMessage', receivableMessage);
        });

        socket.on('update_seen_status', (senderId) => {
          app.config.globalProperties.$store.dispatch('handleUpdateSeenStatus', senderId);
        });
        socket.on('typing_started', (typingStatus, senderId) => {
          app.config.globalProperties.$store.dispatch('handleSocketTyping', {typingStatus, senderId});
        });

        socket.on('got_new_conversation', (receiver_id, initiatedConversation) => {
          
          app.config.globalProperties.$store.dispatch('handleNewConversationReceived', {receiver_id, initiatedConversation}); 
        });

        socket.on('got_new_announcement', (announcement_content) => {
          app.config.globalProperties.$store.dispatch('handleNewAnnouncement');
        });

      }
    });

    app.provide('socket', socket);
  },
};

export default socketPlugin;
 
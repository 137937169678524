import AccountService from '@/services/AccountService';

export default {
  state: {
    account: {
      fullName: '',
      email: '',
      password: '',
      role: '',
      id: '',
    },
    token: localStorage.getItem('token') || ''
  },
  getters: {
    // Your getters, e.g., isAuthenticated
    isAuthenticated: state => !!state.token,
    role: state => state.account.role,
    id: state => state.account.id,
  },
  mutations: {
    SET_TOKEN(state, token) {
      state.token = token;
      localStorage.setItem('token', token);
    },
    SET_ROLE(state, role) {
      role=JSON.stringify(role);
      state.account.role = role;
      localStorage.setItem('role', role);
    },
    SET_ACCOUNT(state, account) {
      state.account = account;
    },
    SET_USER_ID(state, id) {
      state.account.id = id;
      localStorage.setItem('user_id', id); // Save to localStorage
    }, SET_USER_NAME(state, name) {
      state.account.name = name;
      localStorage.setItem('user_name', name); // Save to localStorage
    },
  },
  actions: {
    async login({commit}, account) {
      try {
        const response = await AccountService.login(account);
        if (response.data.success.token) {
          const userId = response.data.success.id;
          commit('SET_ACCOUNT', account);
          commit('SET_ROLE', response.data.success.roles);  // Assuming role is returned in response
          commit('SET_USER_ID', userId); // Commit the user id to the store and save it to local storage
          commit('SET_USER_NAME', response.data.success.name);
          AccountService.setUserToken(response.data.success.token);
        }
        return response;
      } catch (error) {
        console.error('An error occurred while logging in:', error);
        return false;
      }
    },

    async register({commit}, account) {
      try {
        const names = account.fullName.split(' ');
        const firstName = names[0];
        const lastName = names.length > 1 ? names.slice(1).join(' ') : '';

        const apiAccount = {
          first_name: firstName,
          last_name: lastName,
          email: account.email,
          phone_number: account.phone,
          address: account.address
        };

        const response = await AccountService.register(apiAccount);
        if (response.data.success) {
          commit('SET_ACCOUNT', account);
          return true;
        } else {
          throw new Error('Unknown Error');
        }
      } catch (error) {
        console.error('An error occurred while registering:', error);
        throw error;
      }
    },

    async contactUs({commit}, account) {
      try {
        const names = account.fullName.split(' ');
        const firstName = names[0];
        const lastName = names.length > 1 ? names.slice(1).join(' ') : '';

        const apiAccount = {
          first_name: firstName,
          last_name: lastName,
          email: account.email,
          phone_number: account.phone,
          message: account.message
        };

        const response = await AccountService.contactUs(apiAccount);
        if (response.data.success) {
          return true;
        } else {
          new Error('Unknown Error. Please try again later.');
        }
      } catch (error) {
        console.error('An error occurred while registering:', error);
        throw error;
      }
    },

    async forgotPassword({commit}, email) {
      try {
        await AccountService.forgotPassword(email);
        // Handle successful forgot password action
      } catch (error) {
        console.error('An error occurred while resetting the password:', error);
      }
    },
    async resetPassword({commit}, info) {
      try {
        await AccountService.resetPassword(info);
        // Handle successful password reset
      } catch (error) {
        console.error('An error occurred while resetting the password:', error);
      }
    },
    // ... other actions for init etc., calling AccountService methods
  }
};

import axios from 'axios'
const stagingUrl = 'https://portal-api-specialolympicsarizona-org.staging.aimit.io/api/'
const AccountService = {
  async init () {
    return axios({
      // url: axios.defaults.baseURL + 'account/init',
      url: stagingUrl + 'account/init',
      method: 'post'
    })
  },

  async resendRegistrationEmail (email) {
    return axios.post(axios.defaults.baseURL + 'resend-registration-email', email);
  },

  async register (info) {
    return axios.post(axios.defaults.baseURL + 'register', info);
  },

  async contactUs (info) {
    return axios.post(axios.defaults.baseURL + 'contactUs', info);
  },

  async login (info) {
    return axios.post(axios.defaults.baseURL + 'login', info);
  },

  async forgotPassword (email) {
    return axios.post(axios.defaults.baseURL + 'password/email', email);
  },

  async resetPassword (info) {
    return axios.post(axios.defaults.baseURL + 'password/reset', info);
  },

  async fetchRecords (params, module) {
    return axios.get(axios.defaults.baseURL + module + 's', params);
  },

  async updateUser (id, info) {
    return axios.put(axios.defaults.baseURL + 'users/' + id, info);
  },

  setUserToken (token) {
    localStorage.setItem('token', token)
  }
}

export default AccountService
import {debounce} from 'lodash';
import axios from "axios";

export function orderUITableFieldByPosition(fields, _default = 100) {
  return fields.sort((a, b) => (a.meta.position || _default) - (b.meta.position || _default));
}

// This fn strips the word "record" from the end of a string
// and replaces it with the actual record type e.g. AddRecord -> AddUser
export function strReplaceRecord(str, record) {
  return str.replace(/record/gi, '') + " " + record;
}

export function uppercaseFirstLetter(str) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export const updateFilters = (module, filters, getModuleSpec) => {
  const moduleSpec = getModuleSpec(module);
  const idFields = moduleSpec.features.find(f => f.type === 'record').fields.filter(field => field.name.startsWith('id_'));

  // Reset filters
  Object.keys(filters).forEach(key => {
    if (key !== 'search' && key !== 'order_by') {
      delete filters[key];
    }
  });

  // Add new id fields
  idFields.forEach(field => {
    filters[field.name] = null;
  });
};

export const fetchRecordsUtil = async (AccountService, filters, module) => {
  const response = await AccountService.fetchRecords({params: filters}, module);
  return response.data;
};

export const handleFiltersSubmittedUtil = (filters, newFilters) => {
  Object.assign(filters, newFilters);
};

export const handleSearchInputUtil = (filters, value, debouncedSearch) => {
  filters.search = value;
  debouncedSearch(value);
};

export const debouncedSearchUtil = (searchWorker, people, originalPeople) => {
  return debounce((value) => {
    if (value.trim() === '') {
      people.value = JSON.parse(JSON.stringify(originalPeople));
    } else {
      const serializablePeople = JSON.parse(JSON.stringify(people.value));
      searchWorker.postMessage({searchTerm: value, people: serializablePeople});
    }
  }, 50);
};

export const getRoleColorUtil = (role, roleColors) => {
  return roleColors[role] || 'bg-gray-50 text-gray-700 ring-gray-700/20';
};

export const openModalUtil = (ctx, modalType, moduleName) => {
  ctx.modalType = modalType;
  ctx.moduleName = moduleName;
  ctx.showModal.value = true;
};

export function getSpecForModule(moduleName) {
  // console.log("searching for moduleName in module", moduleName)
  const moduleSpec = window.spec.find(s => s.module === moduleName);
  if (moduleSpec) {

    return moduleSpec
  }

  return undefined;
}

export function removeHiddenFields(fields) {
  const filteredFields = fields.filter(field => !field.hidden);
  // // // console.log("filteredFields within removeHiddenFields", filteredFields)
  return filteredFields;
}

export function getFieldsFromModuleRecord(moduleSpec) {
  // console.log("calling getFieldsFromModuleRecord", moduleSpec)
  if (moduleSpec) {
    const record = moduleSpec.features.find(f => f.type === 'record');
    if (!record) {
      console.warn("getFieldsFromModuleRecord called with moduleSpec that has no record")
      return undefined;
    }
    let fields = record ? record.fields : [];
    fields = processFieldNames(fields)
    if (fields.length === 0) {
      console.warn("getFieldsFromModuleRecord called with moduleSpec that has no fields after processing")
      return undefined;
    }
    fields = removeHiddenFields(fields)
    if (fields.length === 0) {
      console.warn("getFieldsFromModuleRecord called with moduleSpec that has no fields after removing hidden fields")
      return undefined;
    }
    // console.log("fields within getFieldsFromModuleRecord", fields)
    return fields;
  } else {
    console.warn("getFieldsFromModuleRecord called with undefined moduleSpec")
  }

  return undefined;
}

// Remove fields that we don't want to show in edit mode
// e.g. id, date_create, date_update, created_by, updated_by, deleted_by, deleted_date, deleted
// and composite fields
export function processFieldNames(fields) {
  const excludeFields = ['id', 'date_create', 'date_update', 'created_by', 'updated_by', 'deleted_by', 'deleted_date', 'deleted'];
  // const excludeFields = [];
  fields = fields.filter(field => !excludeFields.includes(field.name) && !field.composite);
  return fields.map(f => ({...f, name: f.name}));
}

export const stripIDAndPluralize = (fieldName) => {
  const plural = fieldName.startsWith('id_') ? pluralize(fieldName.slice(3)) : pluralize(fieldName);

  return plural;
};

export const stripID = (fieldName) => {
  return fieldName.startsWith('id_') ? fieldName.slice(3) : fieldName;
};

export function getSelectFieldValue(field, record) {
  const fieldValue = record[field.name];
  if (Array.isArray(fieldValue)) {
    return fieldValue.map(r => r.name).join(', ');
  }
  return fieldValue;
}

export async function fetchData(endpoint) {
  const response = await axios.get(endpoint);


  return response.data;
}

export async function updateUser(endpoint, user) {
  return await axios.put(endpoint, user);
}

export async function deleteUser(endpoint) {
  return await axios.delete(endpoint);
}

export function pluralize(str) {
  return str.endsWith('s') ? str : str + 's';
}

export const noValidationErrors = (errors) => {
  return Object.keys(errors).length === 0;
}
export const validateRecord = (fields, record) => {
  const errors = {};
  fields.forEach(({name, type, nullable, minLength, maxLength}) => {
    const value = record[name] || '';
    if (!nullable && !value)
      errors[name] = 'This field is required.';
    if (type === 'email' && !/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(value))
      errors[name] = 'Invalid email format.';
    if (type === 'password' && value.length < 8)
      errors[name] = 'Password must be at least 8 characters.';
    if (type === 'number' && isNaN(value))
      errors[name] = 'Not a valid number.';
    if (minLength && value.length < minLength)
      errors[name] = `Minimum length is ${minLength} characters.`;
    if (maxLength && value.length > maxLength)
      errors[name] = `Maximum length is ${maxLength} characters.`;
    if (type === 'url' && !/^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([/\w .-]*)*\/?$/.test(value))
      errors[name] = 'Invalid URL format.';
  });
  return errors;
};

/**
 * applyUIComponentPropLogic - Filters an array of fields based on hardcoded actionable properties.
 *
 * @param {string} UIComponent - The type of UI component.
 * @param {Array<Object>} fields - An array of field objects.
 *
 * @example
 * const fields = [
 *   { UIAddEditViewRecord: { hidden: true }, UITable: { hidden: false } },
 *   { UIAddEditViewRecord: { hidden: false } },
 *   {}
 * ];
 *
 * const filteredFields1 = applyUIComponentPropLogic('UIAddEditViewRecord', fields);
 * // filteredFields1 will be [{ UIAddEditViewRecord: { hidden: false } }, {}]
 *
 * @returns {Array<Object>} - An array of fields that meet the criteria.
 */
export function applyUIComponentPropLogic(UIComponent, fields) {
  const actionableProps = ['hidden', 'xyz']; // Hardcoded actionable props

  // console.log("applyUIComponentPropLogic called with UIComponent", UIComponent, "and fields", fields)

  return fields.filter(field => {
    let shouldDisplay = true;
    const settings = field[UIComponent];

    if (settings) {
      for (const prop of actionableProps) {
        if (settings.hasOwnProperty(prop)) {
          switch (prop) {
            case 'hidden':
              shouldDisplay = !settings[prop];
              break;
            case 'xyz':
              // Your logic for 'xyz' prop goes here
              break;
            default:
              shouldDisplay = true;
          }
        }
      }
    }

    return shouldDisplay;
  });
}

/**
 * getComponentPropValue - Filters an array of fields based on the UIComponent and property specified.
 *
 * @param {string} UIComponent - The type of UI component.
 * @param {string} prop - The property to evaluate.
 * @param {Array<Object>} fields - An array of field objects.
 *
 * @example
 * const fields = [
 *   { UIAddEditViewRecord: { hidden: true }, UITable: { hidden: false } },
 *   { UIAddEditViewRecord: { hidden: false } },
 *   {}
 * ];
 *
 * const filteredFields1 = getComponentPropValue('UIAddEditViewRecord', 'hidden', fields);
 * // filteredFields1 will be [{ UIAddEditViewRecord: { hidden: false } }, {}]
 *
 * const filteredFields2 = getComponentPropValue('UITable', 'hidden', fields);
 * // filteredFields2 will be [{ UIAddEditViewRecord: { hidden: true }, UITable: { hidden: false } }, { UIAddEditViewRecord: { hidden: false } }, {}]
 *
 * @returns {Array<Object>} - An array of fields that meet the criteria.
 */
export const cleanFieldNames = (fields, record) => {

  // console.log(fields)

  if (!fields || typeof fields !== 'object') {
    return record;
  }

  Object.keys(fields).forEach((name, index) => {
    const type = fields[name];
    if (name.startsWith('id_')) {
      const remaining = name.replace('id_', '');
      const newKey = pluralize(remaining);
      if (record[newKey] !== undefined) {
        record[newKey] = JSON.parse(JSON.stringify(record[newKey]));
      } else {

      }
    }
  });
  return record;
}


export function lerp(start, end, amount) {
  return start * (1 - amount) + end * amount;
}

export const debouncedSearch = debounce(function (query, items, tableFields, callback) {
  const filteredItems = items.filter(item =>
    tableFields.some(field => {
      let value;

      // Handling composite fields
      if (field.UITable?.composite) {
        return field.UITable.composite.fields.some(subField => {
          value = item[subField.field];
          return value && value.toString().toLowerCase().includes(query.toLowerCase());
        });
      }
      // Handling multi-select fields
      else if (field.type === 'multi-select') {
        return item[pluralize(field.name)]?.some(tag => {
          return tag.name.toLowerCase().includes(query.toLowerCase());
        });
      }
      // Handling regular fields
      else {
        value = item[field.name] || '';
        return value && value.toString().toLowerCase().includes(query.toLowerCase());
      }
    })
  );
  callback(filteredItems);
}, 500);


export const resolveCompositeField = (item, composite) => {
  if (!composite?.fields) {
    return '';
  }
  const sortedFields = composite.fields.sort((a, b) => a.position - b.position);
  const values = sortedFields.map((f, index) => {
    if ('record' in f) {
      const obj = item[f.record];
      return obj ? obj[f.field] : '';
    } else {
      return item[f.field] || '';
    }
  });
  const joinWith = composite.joinWith || ' ';
  return values.join(joinWith);
};

/**
 * Resolve a field reference in a data item based on a configuration object.
 *
 * @param {Object} dataItem - The data item containing the fields to resolve.
 * @param {Object} refConfig - The configuration object specifying how to resolve the field.
 * @param {string} refConfig.path - The path to the target data in the data item.
 * @param {string} refConfig.field - The field to resolve in the target data.
 * @param {(Object|Object[])} refConfig.type - Specifies the type of the target data. Use an array to indicate a collection.
 *
 * @returns {string} A string representing the resolved values, joined by commas.
 *
 * @example
 *
 * // Example 1: Resolving a single object
 * const dataItem1 = { area: { name: 'Area51' } };
 * const refConfig1 = { path: 'area', field: 'name', type: {} };
 * const result1 = resolveFieldReference(dataItem1, refConfig1);
 * // console.log(result1);  // Output: "Area51"
 *
 * // Example 2: Resolving a collection of objects
 * const dataItem2 = { documents: [{ file_name: 'file1' }, { file_name: 'file2' }] };
 * const refConfig2 = { path: 'documents', field: 'file_name', type: [{}] };
 * const result2 = resolveFieldReference(dataItem2, refConfig2);
 * // console.log(result2);  // Output: "file1, file2"
 *
 */
export const resolveFieldReference = (dataItem, refConfig) => {
  if (!dataItem || !refConfig) return '';

  const {path, field, type} = refConfig;
  const targetData = dataItem[path];

  if (!targetData) return '';

  const navigateNestedObject = (obj, keys) => {
    if (!obj || !keys.length) return obj;
    const key = keys.shift();
    return navigateNestedObject(obj[key], keys);
  };

  let resolvedValues = [];

  if (Array.isArray(type)) {
    resolvedValues = targetData.map(entry => {
      const nestedFieldKeys = field.split('.');
      return navigateNestedObject(entry, [...nestedFieldKeys]) || '';
    });
  } else {
    const nestedFieldKeys = field.split('.');
    const singleValue = navigateNestedObject(targetData, [...nestedFieldKeys]) || '';
    resolvedValues = [singleValue];
  }

  const delimiter = ', ';
  return resolvedValues.join(delimiter);
};


export const modulePutSingleRecordById = async (module, id, record) => {
  return await axios.put(`${axios.defaults.baseURL}${module}/${id}`, record);
}

export const getFeatureSpecByName = (collection, name) => {
  console.log("params", collection, name)
  const res = collection.features.find(f => f.type === name) ?? {};
  console.log("getFeatureSpecByName", res)
  return res;
}

export const getObjectByKeyValuePair = (collection, key, value) => {

  return collection.find(f => f[key] === value);
}

export function stripObjectToIds(record) {
  return Object.fromEntries(
    Object.entries(record).map(([key, value]) => {
      if (Array.isArray(value) && value.length > 0 && 'id' in value[0]) {
        return [key, value.map(item => item.id)];
      }
      return [key, value];
    })
  );
}

export function getSingleBestContactInfo(contactInfo) {

}


export async function yieldObjectFromRecordByKey(fieldName, idOrObj) {
  let id = idOrObj;
  // console.log("yieldObjectFromRecordByKey called with fieldName", fieldName, "and idOrObj", idOrObj)
  if (typeof idOrObj === 'object') {
    id = idOrObj[yieldId(idOrObj)];
  }
  // console.log("yieldObjectFromRecordByKey id", id)

  try {
    const response = await axios.get(`${axios.defaults.baseURL}yieldRecord/${fieldName}/${id}`);
    const record = response.data.record;
    // console.log("yieldObjectFromRecordByKey record found:", record);
    // console.log("yieldObjectFromRecordByKey returning record[fieldName]:", record[fieldName]);
    return record;
  } catch (error) {
    console.error("Error fetching record:", error);
  }

  return undefined;
}

// check if field name starts with _id
export function isIdField(fieldName) {
  const search = fieldName.toLowerCase().startsWith('id_');
  return search;
}

// Is this a person-ish module?
export function isPersonModule(module) {
  // array of 10 words that are likely to be an alias for a person-ish module
  const personWords = [
    'person', 'user', 'account', 'profile', 'member', 'customer', 'client', 'employee', 'staff', 'volunteer'
  ]
  const largestWordLength = 30;
  if (typeof module === 'string' && module.length <= largestWordLength) {
    return personWords.includes(module);
  }
  // // console.log(`NOTICE: isPersonModule() expects a string less than ${largestWordLength} characters long`);
  return false;
}

function cleanLabel(snakeStr) {
  return snakeStr.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
}

export function formatLabel(label) {
  return label.replace(/[_\.-]/g, ' ')
    .split(' ')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
}

export function titleCase(str) {
  return str.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
}

export function yieldContactInfo(obj) {
  const contactFields = [
    'email',
    'phone_number',
    'mobile_number',
    'address',
    'city',
    'state',
    'zip_code',
    'country',
    'website',
    'linkedin',
    'twitter',
    'facebook',
    'instagram',
    'fax',
    'skype'
  ];

  let foundKey = '';
  let foundValue = '';

  if (Array.isArray(obj)) {
    foundKey = contactFields.find(field => obj.some(item => Object.prototype.hasOwnProperty.call(item, field)));
    if (foundKey) {
      const itemWithField = obj.find(item => Object.prototype.hasOwnProperty.call(item, foundKey));
      foundValue = itemWithField[foundKey];
    }
  } else {
    foundKey = contactFields.find(field => Object.prototype.hasOwnProperty.call(obj, field));
    if (foundKey) {
      foundValue = obj[foundKey];
    }
  }

  const cleanedKey = cleanLabel(foundKey);

  return {key: cleanedKey, value: foundValue};
}

export function getPersonNameFromObject(obj) {
  // search for any of the common keys that might return a full name
  const nameFields = ['full_name', 'fullname', 'name', 'fname', 'display_name', 'username', 'nickname', 'handle', 'alias'];
  const nameField = nameFields.find(field => Object.prototype.hasOwnProperty.call(obj, field));
  if (nameField) {
    return obj[nameField];
  }
  // if we haven't found a name yet, search for what might be a first name
  const firstNameFields = ['first_name', 'firstname', 'fname'];
  const firstNameField = firstNameFields.find(field => Object.prototype.hasOwnProperty.call(obj, field));
  const secondNameFields = ['last_name', 'lastname', 'lname'];
  if (firstNameField) {
    const firstName = obj[firstNameField];
    const secondNameField = secondNameFields.find(field => Object.prototype.hasOwnProperty.call(obj, field));
    if (secondNameField) {
      const secondName = obj[secondNameField];
      return `${firstName} ${secondName}`;
    }
    return firstName;
  }
  // if we still haven't found anything, search with yieldName()
  return yieldName(obj);
}

/**
 * yieldName - Finds and returns a relevant field's value or name from a given object.
 *
 * @param {Object|Array} obj - The object or array of objects to search.
 * @param {boolean} [returnValue=true] - Whether to return the field's value (true) or the field's name (false).
 *
 * @example
 * const dataObj = { name: 'John', age: 30 };
 * const fieldName = yieldName(dataObj, false);  // Returns 'name'
 * const fieldValue = yieldName(dataObj, true);  // Returns 'John'
 *
 * @example
 * const dataArray = [{ name: 'John', age: 30 }, { name: 'Jane', age: 25 }];
 * const fieldName = yieldName(dataArray, false);  // Returns 'name'
 *
 * @returns {string} - The field's value if returnValue is true, or the field's name if returnValue is false.
 */
export function yieldName(obj, returnValue = true) {
  if (typeof obj !== 'object' || obj === null) {
    return '';
  }

  // console.log("yieldName called with obj", obj)

  const fieldNames = [
    'name',
    'label',
    'title',
    'heading',
    'text',
    'display_name',
    'slug',
    'alias',
    'key',
    'key_name',
    'field',
    'identifier',
    'first_name',
    'last_name',
    'file_name',
    'filename',
    'full_name',
    'username',
    'nickname',
    'caption',
    'description',
    'handle',
    'term',
    'tag',
    'attribute',
    'property',
    'code',
    'value',
    'id'
  ];
  let foundValue = '';
  if (Array.isArray(obj)) {
    foundValue = fieldNames.find(field => obj.some(item => Object.prototype.hasOwnProperty.call(item, field)));
  } else {
    foundValue = fieldNames.find(field => Object.prototype.hasOwnProperty.call(obj, field));
    if (foundValue && returnValue) {
      foundValue = obj[foundValue];
    }
  }
  // console.log("yieldName foundValue", foundValue)
  return foundValue;
}

export function yieldId(obj, returnValue = true) {
  if (typeof obj !== 'object' || obj === null) {
    return '';
  }

  const fieldNames = [
    'id',
    'uuid',
    'identifier',
    'key',
    'code',
    'reference',
    'token',
    'slug',
    'hash'
  ];

  let foundValue = '';

  if (Array.isArray(obj)) {
    foundValue = fieldNames.find(field => obj.some(item => Object.prototype.hasOwnProperty.call(item, field)));
  } else {
    foundValue = fieldNames.find(field => Object.prototype.hasOwnProperty.call(obj, field));
    if (foundValue && returnValue) {
      foundValue = obj[foundValue];
    }
  }

  return foundValue;
}


// a function that contains a string of the given value
// or has it as a value in an array mathing either strings or numbers
export function containsValue(value) {
  return (obj) => {
    if (typeof obj === 'number') {
      // console.log("obj is a number and value is", value)
      return obj === value;
    } else if (typeof obj === 'string') {
      // console.log("obj is a string and value is", value)
      return obj === value;
    } else if (Array.isArray(obj)) {
      // console.log("obj is an array and value is", value)
      return obj.includes(value);
    }
    // console.log("obj is neither a string nor an array and value is", value)
    return false;
  };
}

// check of local storage 'role' and return as an JSON Array
export function getRole() {
  const role = localStorage.getItem('role');
  if (role) {
    return JSON.parse(role);
  }
  return 0;
}

// check the permissions object given eg { minRole: 1 } and return true if the user has the required role or lower
export function hasPermission(permissions) {
  // console.log("hasPermission called with permissions", permissions)
  const roles = getRole();
  if (permissions?.minRole) {
    // console.log("checking permissions with role", role)
    // return role <= permissions.minRole;
    return roles.some(role => role <= permissions.minRole);
  }
  return false;
}

// impose composited URL params onto formData formData;
export function imposeURLParams(formData, urlParams) {
  const key = 'id_' + urlParams.module;
  formData[key] = urlParams.id;
  return formData;
}

// function to replace an array of substrs with array of replacements
export function replaceArraySubstrs(str, substrs, replacements) {
  let newStr = str;
  substrs.forEach((substr, index) => {
    newStr = newStr.replace(substr, replacements[index]);
  });
  return newStr;
}

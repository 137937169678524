<template>
  <div
    class="chat-content p-4"
    ref="chatContainer"
    id="chatContainer"
    @scroll="handleChatScreenScroll"
  >
    <div class="flex-1 w-100">
      <div
        v-if="isLoadingMessage"
        class="text-center flex items-center justify-center py-2"
      >
        <img src="/assets/spinner.gif" class="w-8 h-8 mr-2" />
        <span>Loading messages...</span>
      </div>

      <div
        v-if="messagePagination?.current_page == messagePagination?.last_page"
      >
        <div class="flex items-center justify-center flex-col">
          <div class="mb-1 mt-1">
            <div class="w-15 h-15 overflow-hidden rounded-full">
              <img
                :src="
                  selectedConversation?.avatar == null
                    ? 'https://ui-avatars.com/api/?name=' +
                      selectedConversation?.name?.split(' ')[0] +
                      '+' +
                      selectedConversation?.name?.split(' ')[1]
                    : selectedConversation?.avatar
                "
                alt="avatar"
                class="w-16 h-16 rounded-full"
              />
            </div>
          </div>
          <h3 class="pt-0">{{ selectedConversation?.name }}</h3>
          <p class="text-gray-500 text-sm">
            You've been connected with this person.
          </p>
        </div>
      </div>

      <div v-for="(messageByDate, date) in messages" :key="date" class="p-1">
        <div class="message-date">
          <span class="date-line left"></span>
          <span class="date-text">{{ formatDate(date) }}</span>
          <span class="date-line right"></span>
        </div>
        <div
          v-for="(message, i) in messageByDate"
          :key="i"
          class="flex flex-col"
          :style="{
            justifyContent:
              message?.sender_id == loggedInUserId ? 'flex-end' : 'flex-start',
          }"
        >
          <div
            v-if="message?.sender_id == loggedInUserId"
            :class="[
              'message',
              'sender',
              message?.is_next_same_sender && !message?.is_previous_same_sender
                ? 'sender--first'
                : !message?.is_next_same_sender &&
                  message?.is_previous_same_sender
                ? 'sender--last'
                : message?.is_next_same_sender &&
                  message?.is_previous_same_sender
                ? 'sender--middle'
                : '',
            ]"
          >
            <!-- Highlighted Message -->
            <template v-if="message?.attachment_type == 'text'">
              <span v-html="highlightMessage(message?.message, message?.id)" />
            </template>

            <!-- Image Message -->
            <template
              v-else-if="
                message?.is_attachment == 1 &&
                message?.attachment_type == 'image'
              "
            >
              <img
                :src="apiBaseUrl + message.message"
                width="300"
                class="py-1.5"
                style="border-radius: 15px"
                :alt="message.id"
              />
            </template>

            <!-- Video Message -->
            <template
              v-else-if="
                message?.is_attachment == 1 &&
                message?.attachment_type == 'video'
              "
            >
              <video
                controls
                style="
                  width: 300px;
                  height: auto;
                  padding: 1.5px;
                  border-radius: 15px;
                "
              >
                <source :src="apiBaseUrl + message.message" type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </template>

            <!-- Audio Message -->
            <template
              v-else-if="
                message?.is_attachment == 1 &&
                message?.attachment_type == 'audio'
              "
            >
              <audio controls>
                <source :src="apiBaseUrl + message.message" type="audio/mpeg" />
                Your browser does not support the audio tag.
              </audio>
            </template>

            <!-- Text Message -->
            <template v-else>
              <div class="d-flex align-center">
                <i class="fa-regular fa-file"></i>
                <div>{{ getTrimmedUrl(message.message) }}</div>
              </div>
            </template>
          </div>
          <div
            v-else
            :class="[
              'message',
              'receiver',
              message?.is_next_same_sender && !message?.is_previous_same_sender
                ? 'receiver--first'
                : !message?.is_next_same_sender &&
                  message?.is_previous_same_sender
                ? 'receiver--last'
                : message?.is_next_same_sender &&
                  message?.is_previous_same_sender
                ? 'receiver--middle'
                : '',
            ]"
          >
            <!-- Highlighted Message -->
            <template v-if="message?.attachment_type == 'text'">
              <span v-html="highlightMessage(message?.message, message?.id)" />
            </template>

            <!-- Image Message -->
            <template
              v-else-if="
                message?.is_attachment == 1 &&
                message?.attachment_type == 'image'
              "
            >
              <img
                :src="apiBaseUrl + message.message"
                width="300"
                class="py-1.5"
                style="border-radius: 15px"
                :alt="message.id"
              />
            </template>

            <!-- Video Message -->
            <template
              v-else-if="
                message?.is_attachment == 1 &&
                message?.attachment_type == 'video'
              "
            >
              <video
                controls
                style="
                  width: 300px;
                  height: auto;
                  padding: 1.5px;
                  border-radius: 15px;
                "
              >
                <source :src="apiBaseUrl + message.message" type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </template>

            <!-- Audio Message -->
            <template
              v-else-if="
                message?.is_attachment == 1 &&
                message?.attachment_type == 'audio'
              "
            >
              <audio controls>
                <source :src="apiBaseUrl + message.message" type="audio/mpeg" />
                Your browser does not support the audio tag.
              </audio>
            </template>

            <!-- Text Message -->
            <template v-else>
              <div class="d-flex align-center">
                <i class="fa-regular fa-file"></i>
                <div>{{ getTrimmedUrl(message.message) }}</div>
              </div>
            </template>
          </div>
          <div
            :class="
              message?.sender_id == loggedInUserId
                ? 'text-right pr-2'
                : 'text-left pl-2'
            "
            v-if="
              !message?.is_next_same_sender &&
              (!messageByDate[index + 1] ||
                messageByDate[index + 1].sender_id !== message?.sender_id)
            "
            class="message-time"
          >
            {{ message?.createdAt ? formattedTime(message?.createdAt) : "" }}
            <span v-show="message?.sender_id == loggedInUserId">{{
              message?.is_seen == 1 ? " . seen" : " . sent"
            }}</span>
          </div>
        </div>
      </div>
      <div v-show="isTyping" class="flex items-center my-2 typing-indicator">
        <div class="mr-2">
          <div class="w-5 h-5 rounded-full overflow-hidden avatar">
            <img
              :src="
                selectedConversation?.avatar == null
                  ? 'https://ui-avatars.com/api/?name=' +
                    selectedConversation?.name?.split(' ')[0] +
                    '+' +
                    selectedConversation?.name?.split(' ')[1]
                  : selectedConversation?.avatar
              "
              alt="avatar"
              class="w-full h-full object-cover"
            />
          </div>
        </div>
        <div class="flex items-center typing-text">
          <b class="mr-1">{{ selectedConversation?.name }}</b>
          <span>is typing</span>
          <div class="ml-1 flex space-x-1">
            <div class="w-2 h-2 bg-gray-500 rounded-full dot"></div>
            <div class="w-2 h-2 bg-gray-500 rounded-full dot"></div>
            <div class="w-2 h-2 bg-gray-500 rounded-full dot"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, defineProps, computed, watch, nextTick } from "vue";

import store from "@/store";

const loggedInUserId = localStorage.getItem("user_id") ?? null;
const isMessageLoading = ref(true);
const chatContainer = ref(null);
const searchTerm = ref("");
const messageData = ref({});
const isLoadingMessage = ref(false);
const apiBaseUrl = process.env.VUE_APP_API_BASE_URL;

const props = defineProps(["scrollBottom"]);

const messages = computed(() => {
  return store.getters["messages"];
});

const messagePagination = computed(() => {
  return store.getters["messagePagination"];
});

const selectedConversation = computed(() => {
  return store.getters["selectedConversation"];
});

const isTyping = computed(() => {
  return store.getters["isTyping"];
});

const escapeRegExp = (string) => string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");

const highlightMessage = (content, id) => {
  if (!searchTerm.value) return content;
  if (content !== undefined && content !== "") {
    const searchTermRegex = new RegExp(escapeRegExp(searchTerm.value), "gi");
    return content.replace(searchTermRegex, (match) => {
      return `<span class="highlight_chat" style="background: yellow!important; color:black!important;" data-key="${id}">${match}</span>`;
    });
  }
  return content;
};

const formatDate = (date) => {
  const currentDate = new Date();
  const messageDate = new Date(date);
  const timeDifference = (currentDate - messageDate) / (24 * 60 * 60 * 1000);
  if (timeDifference < 1) {
    return "Today";
  } else if (timeDifference < 2) {
    return "Yesterday";
  } else if (timeDifference < 3) {
    const options = { weekday: "long" };
    return messageDate.toLocaleDateString(undefined, options);
  } else if (timeDifference < 7) {
    const options = { weekday: "long" };
    return messageDate.toLocaleDateString(undefined, options);
  } else {
    const options = {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
    };
    return messageDate.toLocaleDateString(undefined, options);
  }
};

const formattedTime = (createdAt) => {
  const dateObject = new Date(createdAt);
  const hours = dateObject.getHours();
  const minutes = dateObject.getMinutes();

  return `${hours.toString().padStart(2, "0")}:${minutes
    .toString()
    .padStart(2, "0")}`;
};

const getTrimmedUrl = (fullUrl) => {
  const toBeTrimmed = "/storage/chat/attachment/";
  if (fullUrl.startsWith(toBeTrimmed)) {
    return fullUrl.slice(toBeTrimmed.length);
  } else {
    return fullUrl;
  }
};

const filteredChatMessages = computed(() => {
  if (!messages || typeof messages !== "object") {
    return {};
  }

  if (searchTerm.value.trim() === "") {
    return messages;
  } else {
    const searchTermRegex = new RegExp(escapeRegExp(searchTerm.value), "gi");
    const filteredMessages = {};

    for (const [date, messagesByDate] of Object.entries(messages)) {
      const filteredMessagesByDate = messagesByDate.filter((message) =>
        message.message.match(searchTermRegex)
      );

      if (filteredMessagesByDate.length > 0) {
        filteredMessages[date] = [...filteredMessagesByDate];
      }
    }
    return filteredMessages;
  }
});

const loadNextMessages = async () => {
  isLoadingMessage.value = true;

  if (
    messagePagination?.value?.next_page <= messagePagination?.value?.last_page
  ) {
    const page_no = messagePagination?.value?.next_page;
    await store.dispatch("getSelectedConversationsMessages", page_no);
  }
  isLoadingMessage.value = false;
};

const handleChatScreenScroll = (event) => {
  const scrollTop = event.target.scrollTop;
  if (scrollTop === 0) loadNextMessages();
  // isShowScrollBottomBtn.value = !(chatContainer.value.scrollHeight - chatContainer.value.clientHeight <= chatContainer.value.scrollTop + 50);
};

const scrollToBottom = async () => {
  await nextTick(() => {
    if (chatContainer.value) {
      const scrollHeight = chatContainer.value.scrollHeight;
      chatContainer.value.scrollTo({
        top: scrollHeight,
        // behavior: 'smooth',
      });
    }
  });
};

const chatDispatch = async (conversationId) => {
  try {
    await store.dispatch("setSelectedChatConversation", conversationId);
    await nextTick();
    await scrollToBottom();
  } finally {
    isMessageLoading.value = false;
    await scrollToBottom();
  }
};

watch(
  () => selectedConversation?.id,
  async () => {
    await chatDispatch(selectedConversation?.id);
  }
);

watch(async () => {
  if (props.scrollBottom) {
    await scrollToBottom();
  }
});

watch(filteredChatMessages, async (newMessages) => {
  if (!searchTerm.value.trim()) {
    await scrollToBottom();
    return;
  }
  const updatedMatchedMessages = [];
  for (const [date, messagesByDate] of Object.entries(newMessages)) {
    const searchTermRegex = new RegExp(escapeRegExp(searchTerm.value), "gi");
    const matchedMessagesByDate = messagesByDate.messages.filter((message) => {
      if (message.is_attachment == 0)
        return message.message.match(searchTermRegex);
    });
    const messagesWithHighlight = matchedMessagesByDate.map((message) => ({
      ...message,
      highlighted: true,
    }));
    updatedMatchedMessages.push({ messages: messagesWithHighlight });
  }
  matchedMessages.value = updatedMatchedMessages.flat();
  const firstMatchedContent = chatContainer.value.querySelector(
    '.highlight_chat:not([data-index="false"])'
  );
  if (firstMatchedContent) {
    const scrollOffset = firstMatchedContent.offsetTop - chatHeaderHeight;
    if (chatContainer.value) {
      window.scrollTo({ top: scrollOffset, behavior: "smooth" });
    }
  }
});
</script>

<style scoped lang="scss">
.chat-content {
  padding: 5px;
  background: white;
  height: 100%;
  width: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 3px !important;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #ccc;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
  }
}

.message-time {
  color: gray;
  font-size: 10px;
  margin-top: 2px;
}

.message {
  display: flex;
  flex-direction: column;
  padding: 8px 12px;
  margin-bottom: 1px;
  font-size: 14px;
  font-weight: normal;
}

.sender,
.receiver {
  max-width: 80%;
  word-wrap: break-word;
  border: 4px;
}

.sender {
  background-color: #4a5568;
  color: #ffffff;
  align-self: flex-end;
  text-align: justify;
  justify-content: flex-end;
  border-radius: 24px;
  &--first {
    border-radius: 24px 24px 4px 24px !important;
  }
  &--middle {
    border-radius: 24px 4px 4px 24px !important;
  }
  &--last {
    border-radius: 24px 4px 24px 24px !important;
  }
}

.receiver {
  background-color: #edf2f7 !important;
  color: #2d3748;
  align-self: flex-start;
  justify-content: flex-start;
  text-align: justify;
  border-radius: 24px;
  &--first {
    border-radius: 24px 24px 24px 4px !important;
  }
  &--middle {
    border-radius: 4px 24px 24px 4px !important;
  }
  &--last {
    border-radius: 4px 24px 24px 24px !important;
  }
}

.message-date {
  color: #ccc;
  font-size: 12px;
  text-align: center;
  margin: 10px 0;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  .date-line {
    height: 1px;
    background-color: #ccc;
  }

  .left {
    left: 0;
    width: 100%;
  }

  .right {
    right: 0;
    width: 100%;
  }

  .date-text {
    white-space: nowrap;
    padding: 0 10px;
    background-color: white;
  }
}
</style>
<style scoped>
.typing-indicator {
  position: absolute;
  background: white;
  bottom: 49px;
  padding: 5px;
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background-color: none;
  padding: 15px;
  margin-bottom: 8px;
}
.dot-container {
  display: flex;
  align-items: center;
  margin-right: 10px; /* Add margin for spacing */
}
.dot {
  width: 6px;
  height: 6px;
  background-color: #94a3b8;
  border-radius: 50%;
  margin: 0 3px;
  animation: bounce 1s infinite;
}
.dot:nth-child(2) {
  animation-delay: 0.2s;
}
.dot:nth-child(3) {
  animation-delay: 0.4s;
}
.typing-text {
  font-size: 14px;
  color: #94a3b8;
}
@keyframes bounce {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-8px);
  }
}
</style>
import {createRouter, createWebHistory} from 'vue-router';
import {getSpecForModule, pluralize} from "@/utils";
import spec from "@/spec.json";

window.spec = spec;
window.projectSpec = spec[0];

const generateRoute = ({path, name, component, meta, children, dynamic, redirect}) => {
    const generatedRoute = {
        path,
        name,
        redirect,
        meta,
        children: children ? children.map(generateRoute) : undefined,
    };

    if (component) {
        generatedRoute.component = () => import(`@/components/${component}.vue`);
    }

    if (dynamic) {
        generatedRoute.props = route => {
            const apiEndpoint = `${pluralize(route.params.module)}/${route.params.id || 'default-id'}`;
            const specValue = getSpecForModule(route.params.module);
            return {apiEndpoint, spec: specValue};
        };
    }

    return generatedRoute;
};

const projectFeature = spec.find(f => f.module === 'project').features;

const dynamicRoutes = projectFeature.find(f => f.type === 'routes').props.map(generateRoute);

// Add a catch-all route for handling non-existent routes
dynamicRoutes.push({
    path: '/:pathMatch(.*)*', // matches any path
    redirect: '/login'        // redirect to /login
});

const router = createRouter({
    history: createWebHistory(),
    routes: dynamicRoutes
});

export default router;

import axios from 'axios';
import { nextTick } from 'vue';
import { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';

const my_user_id = localStorage.getItem('user_id') ?? null;

export default {
  state: {
    loggedInUserId: localStorage.getItem('user_id') ?? null,
    announcementData: 0,
    page: 1,
    limit: 10,
    messageHasNextPage: true,
    messages: {},
    conversations: [],
    messagableUserList: [],
    pinnedConversations: [],
    messagePagination: [{
      current_page: 1,
      next_page: 2,
      last_page: 2,
      per_page: 10,
      total: 0,
    }],
    selectedMessegeData: [],
    isNextPageAvailable: false,
    isTyping: false,
    isSearch: false,
    chatSearchQuery: '',
    selectedConversation: {
      id: null,
      selected_chat_user_id: null,
      avatar: '',
      name: '',
      username: '',
      request_type: '',
      usertype: '',
      sender_id: null,
      timezone: '',
      timezoneInGMT: '',
      is_conversation_accepted: false,
      is_online: false,
      is_pinned: false,
      showAcceptButton: false,
      occupation: '',
      last_seen: '',
    },
    globalUnreadCount: 0,
    unreadConversations: [],
  },
  getters: {
    loggedInUserId: (state) => state.loggedInUserId,
    socket: (state) => state.socket,
    page: (state) => state.page,
    limit: (state) => state.limit,
    messageHasNextPage: (state) => state.messageHasNextPage,
    messages: (state) => state.messages,
    conversations: (state) => state.conversations,
    messagableUserList: (state) => state.messagableUserList,
    pinnedConversations: (state) => state.pinnedConversations,
    messagePagination: (state) => state.messagePagination,
    selectedMessegeData: (state) => state.selectedMessegeData,
    isNextPageAvailable: (state) => state.isNextPageAvailable,
    isTyping: (state) => state.isTyping,
    isSearch: (state) => state.isSearch,
    chatSearchQuery: (state) => state.chatSearchQuery,
    selectedConversation: (state) => state.selectedConversation,
    globalUnreadCount: (state) => state.globalUnreadCount,
    announcementData: (state) => state.announcementData,
    unreadConversations: (state) => state.unreadConversations,
  },
  mutations: {
    SET_GLOBAL_UNREAD_COUNT(state, payload) {
      state.globalUnreadCount = payload;
    },
    SET_CHAT_SEARCH_QUERY(state, payload) {
      state.chatSearchQuery = payload;
    },
    SET_IS_SEARCH(state, payload) {
      state.isSearch = payload;
    },
    SET_CONVERSATIONS(state, payload) {
      state.conversations = payload;
    },
    SET_PINNED_CONVERSATIONS(state, payload) {
      state.pinnedConversations = payload;
    },
    SET_MESSAGABLE_USER_LIST(state, payload) {
      state.messagableUserList = payload;
    },
    SET_MESSAGES(state, payload) {
      state.messages = payload;
    },
    SET_IS_ONLINE(state, { conversationIndex, isOnline, pinned }) {
      const targetArray = pinned ? state.pinnedConversations : state.conversations;
    
      const targetConversation = targetArray[conversationIndex];
      
      if (targetConversation) {
        targetConversation.info.is_online = isOnline;
        
        if (state.selectedConversation.id === targetConversation.id) {
          state.selectedConversation.is_online = isOnline;
        }
      } else {
        console.error(`Invalid index for ${pinned ? 'pinnedConversations' : 'conversations'}:`, conversationIndex);
      }
    },
    
    SET_IS_TYPING(state, payload){
      state.isTyping = payload;
    },
    SET_SELECTED_CHAT_CONTACT(state, payload) {
      state.selectedConversation.id = payload.id;
      state.selectedConversation.selected_chat_user_id = payload.selected_chat_user_id;
      state.selectedConversation.sender_id = payload.sender_id;
      state.selectedConversation.avatar = payload.avatar;
      state.selectedConversation.name = payload.name;
      state.selectedConversation.username = payload.username;
      state.selectedConversation.is_online = payload.is_online;
      state.selectedConversation.is_conversation_accepted = payload.is_conversation_accepted;
      state.selectedConversation.showAcceptButton = payload.showAcceptButton;
      state.selectedConversation.last_seen = payload.last_seen;
    },

    SET_CONVERSATION_MESSAGES(state, { conversationIndex, messages, pagination }) {
      const conversation = state.conversations[conversationIndex];
      conversation.messages = messages;
      conversation.message_pagination = pagination;
    },
    

    SET_SELECTED_MESSAGE_DATA(state, payload) {
      state.selectedMessegeData = payload;
    },

    SET_MESSAGE_PAGINATION(state, payload) {
      state.messagePagination = payload;
    },

    SET_CONVERSATION_UNREAD_COUNT(state, { index, unreadCount, pinned }) {
      if (pinned) {
        if (state.pinnedConversations[index]) {
          state.pinnedConversations[index].unread_count = unreadCount;
        } else {
          console.error('Invalid index for pinnedConversation:', index);
        }
      } else {
        if (state.conversations[index]) {
          state.conversations[index].unread_count = unreadCount;
        } else {
          console.error('Invalid index for conversations:', index);
        }
      }
    },    

    // SET_GLOBAL_UNREAD_COUNT(state, payload) {
    //   state.globalUnreadCount = payload;
    // },

    REMOVE_UNREAD_CONVERSATION(state, index) {
      state.unreadConversations.splice(index, 1);
    },

    SET_LAST_MESSAGE(state, { conversationIndex, lastMessage, updatedAt }) {
      const conversation = state.conversations[conversationIndex];
      conversation.last_message = lastMessage;
      conversation.updatedAt = updatedAt;
    },

    ADD_UNREAD_CONVERSATION(state, conversation) {
      state.unreadConversations.push(conversation);
    },

    REORDER_CONVERSATIONS(state, conversationIndex) {
      const conversation = state.conversations.splice(conversationIndex, 1)[0];
      state.conversations.unshift(conversation);
    },
    PUSH_MESSAGE(state, { formattedDate, messages }) {
      if (!state.messages[formattedDate]) {
        state.messages[formattedDate] = [];
      }
      state.messages[formattedDate].push(...messages);
    },

    TOGGLE_PIN_STATUS(state, { conversationId, status, response }) {
      const sourceIndex =
        response?.status === 'pinned'
          ? state.conversations.findIndex((conv) => conv.id === conversationId)
          : state.pinnedConversations.findIndex((conv) => conv.id === conversationId);

      if (sourceIndex !== -1) {
        const removedConversation = status
          ? state.conversations.splice(sourceIndex, 1)[0]
          : state.pinnedConversations.splice(sourceIndex, 1)[0];

        response?.status === 'pinned'
          ? state.pinnedConversations.unshift(removedConversation)
          : state.conversations.unshift(removedConversation);
      }
    }

  },
  actions: {
    async fetchConversations(context) {
      context.commit("SET_CHAT_SEARCH_QUERY", '');
      context.commit("SET_IS_SEARCH", false);
      try {
        const response = await axios.get('/chat/conversations/list');
        context.commit("SET_CONVERSATIONS", response.data.conversations.data);
        context.commit("SET_PINNED_CONVERSATIONS", response.data.pinnedConversations.data);

        const totalUnreadCount = response.data.conversations.data.reduce(
          (total, conversation) => total + conversation.unread_count,
          0
        ) + response.data.pinnedConversations.data.reduce(
          (total, p_conversation) => total + p_conversation.unread_count,
          0
        );

        context.commit("SET_GLOBAL_UNREAD_COUNT", totalUnreadCount);
      } catch (error) {
        console.error('Error fetching conversations:', error);
      }
    },

    async fetchMessagableUserList(context) {
      try {
        const response = await axios.get('/chat/messagable-user-list');
        context.commit("SET_MESSAGABLE_USER_LIST", response.data.users.data);
      } catch (error) {
        console.error('Error fetching conversations:', error);
      }
    },

    async setSelectedChatConversation(context, conversation) {
      const conversationInfo = conversation?.info || {};

      const lastSeenData = await context.dispatch('humanReadableDate', conversationInfo?.last_seen);

      context.commit('SET_SELECTED_CHAT_CONTACT', {
        id: conversation?.id,
        selected_chat_user_id: conversationInfo?.id,
        sender_id: conversation?.sender_id,
        avatar: conversationInfo?.avatar,
        name: conversationInfo?.name,
        username: conversationInfo?.username,
        is_online: conversationInfo?.is_online,
        is_conversation_accepted: conversation?.is_accepted,
        showAcceptButton:
          conversation?.sender_id !== context.state.loggedInUserId && !conversation?.is_accepted,
        last_seen: lastSeenData,
      });

      const sortedMessages = Object.keys(conversation?.messages || {})
        .sort((a, b) => new Date(a).getTime() - new Date(b).getTime())
        .reduce((acc, date) => {
          acc[date] = conversation?.messages[date];
          return acc;
        }, {});

      context.commit('SET_MESSAGES', sortedMessages);
      context.commit('SET_SELECTED_MESSAGE_DATA', sortedMessages);
      context.commit('SET_MESSAGE_PAGINATION', conversation?.message_pagination);

      const my_id = context.state.loggedInUserId;
      const opposite_id = context.state.selectedConversation?.selected_chat_user_id ;
      if(my_id && opposite_id){
        app._vnode.appContext.config.globalProperties.$socket.emit('set_all_messages_seen', { my_id, opposite_id });
      }
      context.dispatch('emptyConversationUnreadCount', conversation?.id);
      context.dispatch('scrollToBottom');
      const user_id = localStorage.getItem('user_id');
      let sender_id = conversation?.sender_id;
      if (user_id == conversation?.sender_id) {
        sender_id = conversation?.receiver_id;
      } else {
        sender_id = conversation?.sender_id;
      }
      try {
        await axios.post('/chat/conversations/mark-as-seen', {
            conversation_id: conversation?.id,
            sender_id: sender_id,
            receiver_id: user_id,
        });
        console.log('All messages marked as seen successfully');
    } catch (error) {
        console.error('Error marking all messages as seen:', error);
    }
    },

    async emptyConversationUnreadCount(context, conversationId) {
      const index = context.state.conversations.findIndex(
        conversation => conversation.id === conversationId
      );
    
      if (index !== -1 && context.state.conversations[index].unread_count > 0) {
        const unreadCountToRemove = context.state.conversations[index].unread_count;
        context.commit('SET_CONVERSATION_UNREAD_COUNT', { index, unreadCount: 0, pinned: false });

        context.commit('SET_GLOBAL_UNREAD_COUNT', Math.max(0, context.state.globalUnreadCount - unreadCountToRemove));

        const unreadIndex = context.state.unreadConversations.findIndex(
          conv => conv.id === conversationId
        );
    
        if (unreadIndex !== -1) {
          context.commit('REMOVE_UNREAD_CONVERSATION', unreadIndex);
        }

      }
    
      const pinnedIndex = context.state.pinnedConversations.findIndex(
        conversation => conversation.id === conversationId
      );
    
      if (pinnedIndex !== -1 && context.state.pinnedConversations[pinnedIndex].unread_count > 0) {
        const unreadCountToRemove = context.state.pinnedConversations[pinnedIndex].unread_count;
        context.commit('SET_CONVERSATION_UNREAD_COUNT', { index: pinnedIndex, unreadCount: 0, pinned: true });

        context.commit('SET_GLOBAL_UNREAD_COUNT', Math.max(0, context.state.globalUnreadCount - unreadCountToRemove));
        
        const unreadIndex = context.state.unreadConversations.findIndex(
          conv => conv.id === conversationId
        );
    
        if (unreadIndex !== -1) {
          context.commit('REMOVE_UNREAD_CONVERSATION', unreadIndex);
        }
      }
    },    

    async updateLastMessage(context, { msgData, isSelf = false }) {
      const dateKey = Object.keys(msgData)[0];
      const messageData = msgData[dateKey][0];
    
      if (!messageData?.conversation_id) {
        return;
      }
    
      const conversationId = messageData.conversation_id;
      const conversationIndex = context.state.conversations.findIndex(
        conversation => conversation.id == conversationId
      );
    
      const pinnedConversationIndex = context.state.pinnedConversations.findIndex(
        conversation => conversation.id == conversationId
      );
    
      if (conversationIndex !== -1) {
    
        const lastMessage = messageData.is_attachment == 1 ? '[Attachment]' : messageData.message;
        context.state.conversations[conversationIndex].last_message = lastMessage;
        context.state.conversations[conversationIndex].updatedAt = messageData.createdAt;
        if ((messageData.sender_id != context.state.selectedConversation.selected_chat_user_id) && !isSelf) {
          context.state.conversations[conversationIndex].unread_count += 1;
          context.state.globalUnreadCount += 1;
          
          const conversation = context.state.conversations[conversationIndex]

          context.state.conversations.splice(conversationIndex, 1);
          context.state.conversations.unshift(conversation);

          context.commit('ADD_UNREAD_CONVERSATION', conversation);
          context.state.conversations[conversationIndex].messages[dateKey].push(messageData);
        }
        
    
      }else if(pinnedConversationIndex !== -1) {
        const pinnedConversation = context.state.pinnedConversations[pinnedConversationIndex]
    
        const lastMessage = messageData.is_attachment == 1 ? '[Attachment]' : messageData.message;
    
        context.state.pinnedConversations[pinnedConversationIndex].last_message = lastMessage;
        context.state.pinnedConversations[pinnedConversationIndex].updatedAt = messageData.createdAt;
    
        if (messageData.sender_id != context.state.selectedConversation.selected_chat_user_id && !isSelf) {
          context.state.pinnedConversations[pinnedConversationIndex].unread_count += 1;
          context.state.globalUnreadCount += 1;
    
          context.state.pinnedConversations.splice(pinnedConversationIndex, 1);
          context.state.pinnedConversations.unshift(pinnedConversation);

          context.commit('ADD_UNREAD_CONVERSATION', pinnedConversation);
          context.state.pinnedConversations[pinnedConversationIndex].messages[dateKey].push(messageData);
        }
      }
      
      else {
        console.log('Conversation not found in the conversations list.');
      }
    },
    
    async pushMessage(context, messageData) {
      const dateKey = Object.keys(messageData)[0];
      const formattedDate = dateKey;

      context.commit('PUSH_MESSAGE', { formattedDate, messages: messageData[dateKey] });
      await context.dispatch('updateLastMessage', { msgData: messageData, isSelf: true });
      await context.dispatch('scrollToBottom');
    },

    async scrollToBottom(context) {
      await nextTick(() => {
        const container = document.getElementById('chatContainer');
        if (container) {
          container.scrollTo({ top: container.scrollHeight });
        }
      });
    },

    async humanReadableDate(context, dateString) {
      const now = new Date();
      const date = new Date(dateString);
      const seconds = Math.floor((now.getTime() - date.getTime()) / 1000);
      if (seconds === 0) return 'Last seen recently';
    
      const timeUnits = [
        { unit: 'year', seconds: 31536000 },
        { unit: 'month', seconds: 2592000 },
        { unit: 'day', seconds: 86400 },
        { unit: 'hour', seconds: 3600 },
        { unit: 'minute', seconds: 60 },
      ];
    
      for (const unit of timeUnits) {
        const count = Math.floor(seconds / unit.seconds);
        if (count > 0)
          return `Last seen ${count} ${unit.unit}${count !== 1 ? 's' : ''} ago`;
      }
      return 'Last seen recently';
    },

    async togglePinStatus(context, { conversationId, status }) {
      try {
        const response = await axios.put(`/conversations/toggle-pin/${conversationId}/${status}`);
        if (response?.status) {
          context.commit('TOGGLE_PIN_STATUS', { conversationId, status, response });
        }
      } catch (error) {
        console.error('Error toggling pin status:', error);
      }
    },

    async handleOnlineStatus(context, { uId, isOnline }) {
      const updateOnlineStatus = (array, index, pinned) => {
        if (index !== -1) {
          const targetConversation = array[index].info;
          targetConversation.is_online = isOnline;
          context.commit('SET_IS_ONLINE', { conversationIndex: index, isOnline, pinned });
    
          if (!isOnline) {
            targetConversation.last_seen = new Date().toISOString();
          }
    
          if (context.state.isTyping && !isOnline) {
            context.commit('SET_IS_TYPING', false);
          }
        }
      };
    
      const index = context.state.conversations.findIndex(
        conversation => conversation.info.id == uId
      );
    
      const pinnedIndex = context.state.pinnedConversations.findIndex(
        conversation => conversation.info.id == uId
      );
    
      updateOnlineStatus(context.state.conversations, index, false);
      updateOnlineStatus(context.state.pinnedConversations, pinnedIndex, true);
    },    

    async sendMessageToChatServer(context, formattedTempMessage) {
      app._vnode.appContext.config.globalProperties.$socket.emit('send_message', formattedTempMessage);
    },

    async sendMessage(context, messageInput) {
      if (!messageInput) {
        return;
      }
      try {
        const tempId = Date.now();
    
        const tempMessage = {
          id: tempId,
          message: messageInput,
          is_seen: '0',
          is_attachment: '0',
          attachment_type: 'text',
          conversation_id: context.state.selectedConversation.id,
          sender_id: context.state.loggedInUserId,
          receiver_id: context.state.selectedConversation.selected_chat_user_id,
          createdAt: new Date().toISOString(),
          updatedAt: new Date().toISOString(),
          is_next_same_sender: false,
          is_previous_same_sender: false,
        };
    
        const formattedTempMessage = {
          [tempMessage.createdAt.substr(0, 10)]: [tempMessage],
        };
    
        await context.dispatch('pushMessage', formattedTempMessage);
        await context.dispatch('sendMessageToChatServer', formattedTempMessage);
    
        const formData = new FormData();
        formData.append('conversation_id', context.state.selectedConversation.id);
        formData.append('sender_id', context.state.loggedInUserId);
        formData.append('receiver_id', context.state.selectedConversation.selected_chat_user_id);
        formData.append('message', messageInput);
        formData.append('is_seen', '0');
        formData.append('is_attachment', '0');
        formData.append('attachment_type', 'text');
    
        await axios.post('/chat/messages/store', formData);
    
        return true;
      } catch (error) {
        console.error('Error sending message:', error);
      }
    
      return false;
    },

    async sendMessageWithAttachment(context, image) {
      try {
          const formData = new FormData();
          formData.append('conversation_id', context.state.selectedConversation.id);
          formData.append('message', image);
          formData.append('sender_id', context.state.loggedInUserId);
          formData.append('receiver_id', context.state.selectedConversation.selected_chat_user_id);
          formData.append('is_seen', '0');
  
          const response = await axios.post('/chat/messages/store-attachment',formData);
  
          if (response?.data?.messages) {
              const formattedTempMessage = response.data.messages;
              await context.dispatch('pushMessage', formattedTempMessage);
              await context.dispatch('sendMessageToChatServer', formattedTempMessage);
          } else {
              console.error('Error uploading file:', response.statusText);
          }
      } catch (error) {
          console.error('Error uploading file:', error);
      }
    },  

    async receiveMessage(context, receivableMessage){
      for (const dateKey in receivableMessage) {
        const messagesInDate = receivableMessage[dateKey];

        for (let i = 0; i < messagesInDate.length; i++) {
          const receivableMessageData = messagesInDate[i];
          if (receivableMessageData.sender_id == context.state.selectedConversation.selected_chat_user_id) {
            const formattedTempMessage = { [dateKey]: [receivableMessageData] };
            await context.dispatch('pushMessage', formattedTempMessage);
            await context.dispatch('scrollToBottom');
            const myId = context.state.loggedInUserId;
            const oppositeId = context.state.selectedConversation.selected_chat_user_id;
            app._vnode.appContext.config.globalProperties.$socket.emit('set_all_messages_seen', { my_id: myId, opposite_id: oppositeId });
           }
            toast.success('You got a new message!', {
              autoClose: 3000,
              position: toast.POSITION.TOP_CENTER,
              pauseOnHover: true,
              closeButton: true,
              theme: 'colored',
            });
          await context.dispatch('updateLastMessage', { msgData: { [dateKey]: [receivableMessageData] }, isSelf: false });
        }
      }
    },

    async appendMessages(context, { messageData, pagination }){
      context.commit('SET_MESSAGE_PAGINATION', pagination);
    
      const updatedMessageData = { ...context.state.selectedMessegeData };
    
      for (const date in messageData) {
        if (!updatedMessageData[date]) updatedMessageData[date] = [];
        const combinedMessages = [
          ...messageData[date],
          ...updatedMessageData[date],
        ];
        updatedMessageData[date] = combinedMessages;
      }

      const sortedMessages = Object.keys(updatedMessageData)
        .sort((a, b) => new Date(a).getTime() - new Date(b).getTime())
        .reduce((acc, date) => {
          acc[date] = updatedMessageData[date];
          return acc;
        }, {});
    
      const conversationIndex = context.state.conversations.findIndex(
        conversation => conversation.id === context.state.selectedConversation.id
      );
      if (conversationIndex !== -1) {
        context.commit('SET_CONVERSATION_MESSAGES', { conversationIndex, messages: sortedMessages, pagination });
      }
      console.log(sortedMessages);
      context.commit('SET_SELECTED_MESSAGE_DATA', sortedMessages);
      context.commit('SET_MESSAGES', sortedMessages);
    },
    
    async getSelectedConversationsMessages(context, page_no) {
      try {
        const response = await axios.get(`/chat/messages/${context.state.selectedConversation.id}?page=${page_no}`);

        
        const messageData = response.data.messages;
        const pagination = response.data.message_pagination;
        await context.dispatch('appendMessages', {messageData, pagination});
  
        const myId = context.state.loggedInUserId;
        const oppositeId = context.state.selectedConversation.selected_chat_user_id;

        app._vnode.appContext.config.globalProperties.$socket.emit('set_all_messages_seen', { my_id: myId, opposite_id: oppositeId });

        context.dispatch('emptyConversationUnreadCount', context.state.selectedConversation?.id);
      } catch (error) {
        console.error('Error: Fetching Data');
      }
    },

    async removeMessageHighlights(context) {
      for (const dateKey in context.state.messages) {
        const messagesInDate = context.state.messages[dateKey];
    
        for (let i = 0; i < messagesInDate.length; i++) {
          const message = messagesInDate[i];
    
          if (message.message) {
            message.message = message.message.replace(/<span[^>]*class="highlight_chat"[^>]*>(.*?)<\/span>/gi, '$1');
          }
        }
      }
    },

    async searchMessageGlobally(context, messageSearchQuery) {

      context.commit('SET_CHAT_SEARCH_QUERY', messageSearchQuery);
      context.commit('SET_IS_SEARCH', true);
    
      try {
        const response = await axios.get(
          `/chat/conversations/global-search/${messageSearchQuery}`
        );
    
        context.commit('SET_CONVERSATIONS', response.data.conversations.data);
        let conversation;
        if (response.conversations?.data[0]?.id) {
          conversation = response.data.conversations.data[0]
        } else {
          conversation = {
            id: null,
            selected_chat_user_id: null,
            avatar: '',
            name: '',
            username: '',
            request_type: '',
            usertype: '',
            sender_id: null,
            timezone: '',
            timezoneInGMT: '',
            is_conversation_accepted: false,
            is_online: false,
            is_pinned: false,
            showAcceptButton: false,
            occupation: '',
            last_seen: '',
          }
        }
        await context.dispatch('setSelectedChatConversation', conversation);
      } catch (error) {
        console.error('Error searching message:', error);
      }
    },

    async handleUpdateSeenStatus (context, senderId){
      for (const dateKey in context.state.messages) {
          const messagesInDate = context.state.messages[dateKey];
          for (let i = 0; i < messagesInDate.length; i++) {
              const message = messagesInDate[i];
              if (message.sender_id == senderId && message.is_seen == '0') {
                  message.is_seen = true;
              }
          }
      }
    },
  
    async handleTyping(context, typingStatus){
      const sender_id = context.state.loggedInUserId;
      const receiver_id =  context.state.selectedConversation?.selected_chat_user_id;
      const senderId = receiver_id;
      context.dispatch('emptyConversationUnreadCount', context.state.selectedConversation?.id);
      context.dispatch('handleUpdateSeenStatus', senderId);
      app._vnode.appContext.config.globalProperties.$socket.emit('typing', { sender_id, receiver_id, typingStatus });
    },

    async handleSocketTyping(context, {typingStatus, senderId}){
      if (context.state.selectedConversation.selected_chat_user_id == senderId) {
        context.commit('SET_IS_TYPING', typingStatus);
        if (!context.state.selectedConversation.is_online) {
          context.state.selectedConversation.is_online = true;
        }
      }
    },

    async togglePinStatus(context, { conversationId, status }) {
      try {
        const response = await axios.put(
          `/chat/conversations/toggle-pin/${conversationId}/${status}`
        );
        
        if (response && response.data && response.data.status) {
          const sourceIndex = response.data.status === 'pinned'
            ? context.state.conversations.findIndex((conv) => conv.id === conversationId)
            : context.state.pinnedConversations.findIndex((conv) => conv.id === conversationId);
    
          if (sourceIndex !== -1) {

            if(response.data.status === 'pinned'){
              context.state.conversations[sourceIndex].is_pinned = true;
            }else{
              context.state.pinnedConversations[sourceIndex].is_pinned = false;
            }
            
            // Remove the conversation from the source array
            const removedConversation = status
              ? context.state.conversations.splice(sourceIndex, 1)[0]
              : context.state.pinnedConversations.splice(sourceIndex, 1)[0];
    
            // Add the conversation to the target array
            response.data.status === 'pinned'
              ? context.state.pinnedConversations.unshift(removedConversation)
              : context.state.conversations.unshift(removedConversation);
          }
        }
      } catch (error) {
        console.error('Error fetching conversations:', error);
      }
    },

    async conversationInitiated(context, {receiver_id, initiatedConversation}){
      app._vnode.appContext.config.globalProperties.$socket.emit('newConversation', {receiver_id, initiatedConversation});
    },

    async handleNewConversationReceived(context, { receiver_id, initiatedConversation }) {
      console.log("got new conversation" + receiver_id);
      if (parseInt(context.state.loggedInUserId) === receiver_id) {
       
        toast.success('You got a new chat!', {
          autoClose: 3000,
          position: toast.POSITION.TOP_CENTER,
          pauseOnHover: true,
          closeButton: true,
          theme: 'colored',
        });
        context.state.conversations.push(initiatedConversation);
      }
    },

    async makeAnnouncement (context, formData){
      try {
        const response = await axios.post('/chat/make-announcement',formData);
        
        if (response?.data?.created) {
          const receiver_ids = response?.data?.receiver_ids;
          const announcement_content = response?.data?.announcement_content;
          console.log('chat', response.data);
          app._vnode.appContext.config.globalProperties.$socket.emit('newAnnouncement', {receiver_ids, announcement_content});
        }
      } catch (error) {
        console.error('Error making announcement:', error);
      }
    },

    async handleNewAnnouncement(context){
      context.state.announcementData += 1;
      toast.success('You got a new announcement!', {
        autoClose: 3000,
        position: toast.POSITION.TOP_CENTER,
        pauseOnHover: true,
        closeButton: true,
        theme: 'colored',
      });
    }
    

  }
};

<template>
  <div class="chat-drawer fixed bottom-0 right-5 shadow-md" v-show="!hideDrawer">
    <!-- Header -->
    <div
      :class="[
        'bg-red-500 text-white p-3 cursor-pointer flex justify-between items-center',
        'chat-header',
      ]"
    >
      <div @click="toggleDrawer" class="flex items-center w-full">
        <i
          @click.stop
          v-if="selectedConversation?.id"
          :class="['fas', 'fa-arrow-left', 'mr-2', 'text-lg', 'bg-red-500']"
          @click="setSelectedConversationEmpty()"
        ></i>
        <div v-else class="flex">
          <i
            :class="['fas', 'fa-comment-alt', 'mr-2', 'text-lg', 'bg-red-500']"
          ></i>
          <h2 class="text-md font-semibold">Messages</h2>
          <div
            v-show="globalUnreadCount > 0"
            class="ml-2 flex h-4.5 min-w-[1.125rem] items-center justify-center rounded-full bg-white px-1.5 text-tiny+ font-medium leading-none text-red-500"
          >
            {{ globalUnreadCount }}
          </div>
        </div>
        <div class="flex flex-row" v-if="selectedConversation?.id">
          <div class="flex items-center">
            <div class="relative mr-4">
              <img
                :src="
                  selectedConversation?.avatar == null
                    ? 'https://ui-avatars.com/api/?name=' +
                      selectedConversation?.name?.split(' ')[0] +
                      '+' +
                      selectedConversation?.name?.split(' ')[1]
                    : selectedConversation?.avatar
                "
                alt="Avatar"
                class="w-8 h-8 rounded-full"
              />
              <div
                v-if="selectedConversation?.is_online"
                class="absolute bottom-0 right-0 w-2 h-2 bg-green-500 border border-white rounded-full"
              ></div>
              <div
                v-else
                class="absolute bottom-0 right-0 w-2 h-2 bg-gray-500 border border-white rounded-full"
              ></div>
            </div>
            <div>
              <h1 class="text-md font-lighter">
                {{
                  selectedConversation?.name.slice(0, 12) +
                  (selectedConversation?.name.length > 12 ? "..." : "")
                }}
              </h1>
              <p class="text-xs text-white">
                {{
                  selectedConversation?.is_online
                    ? "Active now"
                    : selectedConversation?.last_seen
                }}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="flex items-center" @click.stop>
        <i
          class="fa-solid fa-comment-medical text-white mr-4"
          @click.stop="openChatModal"
        ></i>
        <router-link to="/messenger">
          <i class="fas fa-expand text-white mr-4"></i>
        </router-link>
        <i
          @click="toggleDrawer"
          :class="[
            'fas',
            isOpen ? 'fa-angles-down' : 'fa-angles-up',
            'text-white mr-2',
          ]"
        ></i>
        <i @click.stop="hideDrawer = true" class="fas fa-xmark text-white mr-2"></i>
      </div>
    </div>

    <!-- Chat Content -->
    <transition name="slide" class="bg-white">
      <div v-show="isOpen">
        <ConversationList
          v-show="!selectedConversation?.id"
          @scroll-to-bottom="handleScrollToBottom"
          @selectConversation="setSelectedConversation(selectedConversation)"
          @close="closeDrawer"
        />
        <div v-show="selectedConversation?.id" class="chat-wrapper">
          <ChatContent :scrollBottom="callScrollToBottom" />
        </div>
      </div>
    </transition>

    <!-- Chat Input -->
    <transition name="slide">
      <div
        v-show="isOpen && selectedConversation?.id"
        class="p-1 border-t border-gray-200 bg-white"
      >
        <div class="flex items-center relative">
          <!-- Attachment Button (on the left) -->
          <!-- <div
            class="w-7 h-7 rounded-full bg-red-500 text-white text-center items-center flex justify-center mr-1"
          >
            <button
              @click="$refs.fileInput.click()"
              class="w-7 h-7 rounded-full text-center items-center flex justify-center focus:outline-none hover:bg-red-800 hover:text-white"
            >
              <i class="fas fa-paperclip"></i>
            </button>
          </div> -->

          <!-- Image preview Area -->
          <div v-if="imagePreviewURL || isFileAttachment" class="relative">
            <button
              @click="closeImagePreview"
              class="absolute -top-1 -right-1 w-4 h-4 leading-none bg-red-500 rounded-full text-white z-10"
            >
              <i class="fa-solid fa-xmark"></i>
            </button>

            <div
              v-if="isFileAttachment"
              class="flex items-center text-4xl text-primary"
            >
              <i class="fa-solid fa-paperclip"></i>
            </div>
            <img
              v-else
              :src="imagePreviewURL"
              alt="Image Preview"
              class="w-12 h-12 rounded z-0"
            />
          </div>

          <!-- Text Area -->
          <template v-else>
            <div class="relative flex-1">
              <textarea
                rows="1"
                @input="handleInput"
                v-model="messageInput"
                @keyup.enter="sendMessageOnEnter"
                placeholder="Type your message..."
                class="w-full pl-6 pr-12 py-2 focus:outline-none h-auto"
                style="font-size: 11px; width: 100%"
              ></textarea>
            </div>

            <!-- Emoji Picker Button -->
            <div
              class="w-7 h-7 rounded-full bg-red-500 text-white text-center items-center flex justify-center mx-1"
            >
              <button
                @click="showEmojiPicker = !showEmojiPicker"
                class="w-7 h-7 rounded-full text-center items-center flex justify-center focus:outline-none hover:bg-red-800 hover:text-white"
              >
                <i class="far fa-smile"></i>
              </button>
            </div>
          </template>

          <!-- Send Button (on the right) -->
          <div
            class="w-7 h-7 rounded-full bg-red-500 text-white text-center items-center flex justify-center ml-auto"
          >
            <button
              class="w-7 h-7 rounded-full text-center items-center flex justify-center focus:outline-none hover:bg-red-800 hover:text-white"
              @click="sendMessage"
            >
              <i class="fas fa-paper-plane"></i>
            </button>
          </div>

          <div
            v-show="showEmojiPicker"
            x-show="showEmojiPicker"
            style="z-index: 1015 !important"
            class="popover w-100 mb-2 absolute bottom-full right-0"
          >
            <EmojiPicker
              :native="true"
              display-recent
              mode="insert"
              @select="handleEmojiSelect"
            />
          </div>

          <!-- File Input -->
          <input
            ref="fileInput"
            type="file"
            accept=".jpg, .jpeg, .webp, .svg, .png, .gif, .pdf, .docx, .xlsx, .pptx, .mp4, .mp3, .wav, .txt"
            style="display: none"
            @change="handleFileChange"
          />
        </div>
      </div>
    </transition>

    <ChatModal v-if="isChatModalOpen" @close="closeChatModal" />
  </div>
</template>

<script setup>
import ConversationList from "./ConversationList.vue";
import ChatContent from "./ChatContent.vue";
import ChatModal from "@/components/modals/ChatModal.vue";
import { ref, onMounted, defineProps, computed, nextTick } from "vue";
import EmojiPicker from "vue3-emoji-picker";
import "vue3-emoji-picker/css";

import store from "@/store";

const isChatModalOpen = ref(false);
const callScrollToBottom = ref(false);
const messageInput = ref("");
const selectedImage = ref(null);
const imagePreviewURL = ref(null);
const isFileAttachment = ref(false);
const showEmojiPicker = ref(false);
const hideDrawer = ref(false);

const openChatModal = () => {
  isChatModalOpen.value = true;
};

const closeChatModal = () => {
  isChatModalOpen.value = false;
};

const handleEmojiSelect = (emoji) => {
  messageInput.value += emoji.i;
};

const isOpen = ref(false);
const newMessage = ref("");

const toggleDrawer = () => {
  isOpen.value = !isOpen.value;
};

const closeDrawer = () => {
  isOpen.value = false;
};

const closeImagePreview = () => {
  selectedImage.value = null;
  imagePreviewURL.value = null;
  isFileAttachment.value = false;
};

const determineAttachmentType = (attachment) => {
  const fileExtension = attachment.name.split(".").pop().toLowerCase();
  if (["jpg", "jpeg", "webp", "svg", "png", "gif"].includes(fileExtension)) {
    return "image";
  } else {
    return "other";
  }
};

const handleFileChange = (event) => {
  const file = event.target.files[0];
  if (file) {
    selectedImage.value = file;
    // Determine the type of attachment
    const attachmentType = determineAttachmentType(file);
    if (attachmentType == "image") {
      isFileAttachment.value = false;
      imagePreviewURL.value = URL.createObjectURL(file);
    } else {
      imagePreviewURL.value = null;
      isFileAttachment.value = true;
    }
  }
};

const setSelectedConversation = async (conversation) => {
  await store.dispatch("setSelectedChatConversation", conversation);
  emits("scroll-to-bottom", true);
};

const setSelectedConversationEmpty = async () => {
  const conversation = {
    id: null,
    selected_chat_user_id: null,
    avatar: "",
    name: "",
    username: "",
    request_type: "",
    usertype: "",
    sender_id: null,
    timezone: "",
    timezoneInGMT: "",
    is_conversation_accepted: false,
    is_online: false,
    is_pinned: false,
    showAcceptButton: false,
    occupation: "",
    last_seen: "",
  };
  await store.dispatch("setSelectedChatConversation", conversation);
};

const handleScrollToBottom = async () => {
  callScrollToBottom.value = true;
  await nextTick();
  callScrollToBottom.value = false;
};

const selectedConversation = computed(() => {
  return store.getters["selectedConversation"];
});

const globalUnreadCount = computed(() => {
  console.log(store.getters["globalUnreadCount"]);
  return store.getters["globalUnreadCount"];
});

const handleInput = async () => {
  if (showEmojiPicker.value) {
    showEmojiPicker.value = false;
  }
  const typing = messageInput.value.trim() !== "";
  await store.dispatch("handleTyping", typing);
};

const sendMessageOnEnter = async (event) => {
  if (!event.shiftKey && event.key === "Enter") {
    event.preventDefault();
    await sendMessage();
    messageInput.value = "";
  }
};

const sendMessage = async () => {
  if (selectedImage?.value) {
    const image = selectedImage.value;
    await store.dispatch("sendMessageWithAttachment", image);
    closeImagePreview();
  } else if (messageInput.value.trim() !== "") {
    const messageData = messageInput.value.trim();
    messageInput.value = "";
    await store.dispatch("sendMessage", messageData);
    const typing = false;
    await store.dispatch("handleTyping", typing);
  }

  messageInput.value = "";
  closeImagePreview();
};

const conversations = computed(() => {
  return store.getters["conversations"];
});

onMounted(async () => {
  if (
    localStorage.getItem("user_id") &&
    localStorage.getItem("user_id") > 0 &&
    !conversations?.value?.length > 0
  ) {
    await store.dispatch("fetchConversations");
  }

  // if (store.state.conversations?.value?.length > 0) {
  //   const conversationsData = store.state.conversations.filter(
  //     conversation => conversation.info.id !== store.state.loggedInUserId?.id
  //   );
  //   await store.dispatch(setSelectedConversation(conversationsData[0]));
  //   isConversationLoading.value = false;
  // }
});
</script>

<style scoped lang="scss">
.chat-drawer {
  z-index: 1014 !important;
  width: 350px;
  overflow: hidden;
}
.chat-wrapper {
  height: 350px;
  width: 350px;
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: #ccc transparent;
}
.chat-header {
  border-radius: 8px 8px 0 0;
}

.slide-enter,
.slide-leave-to {
  height: 0;
  overflow: hidden;
}
</style>

<script setup>
import { computed, ref, onMounted, nextTick } from 'vue';

import {RouterView, useRoute} from 'vue-router';
import ChatDrawer from '@/components/chat/ChatDrawer.vue';

const route = useRoute();
const hasConnected = ref(false);

const showChatDrawer = computed(() => {
  const excludedPaths = ['/messenger', '/login', '/logout'];
  return !excludedPaths.includes(route.path);
});

const getUserID = () => {
  const userID = localStorage.getItem('user_id');
  return userID ? parseInt(userID) : null;
}

onMounted( async () => {
  await nextTick(() => {
    const myUserId = getUserID();
    const token = localStorage.getItem('token') || null;
    if(token && myUserId > 0 && !hasConnected.value){
      app._vnode.appContext.config.globalProperties.$socket.emit('connected', myUserId);
      hasConnected.value = true;
    }
  })
});

</script>

<template>
<div>
    <ChatDrawer v-if="hasConnected && showChatDrawer && getUserID() > 0" /> 
    <nav>
      <RouterView />
    </nav>
  </div>
</template>

<style lang="scss">
.dp-custom-input {
  margin-bottom: 10px;
  border-color: #cacaca;
  padding: 10px;

  &:hover {
    border-color: #1976d2;
  }
}
</style>



<template>
  <div
    class="fixed inset-0 z-50 overflow-auto bg-black bg-opacity-50 flex items-center justify-center"
  >
    <div class="bg-white p-8 rounded-lg cw-500">
      
        <!-- Contact List -->
        <div class="flex items-center justify-between mb-4">
          <h1 class="text-xl">{{ isAnnouncement ? 'Make Announcement' : 'New Message' }}</h1>
          <!-- <button @click="handleAnnouncement" class="bg-red-500 text-white px-4 py-2 rounded-lg">
            <i class="fa-solid fa-bullhorn mr-2"></i>Make an Announcement
          </button> -->
          <button v-if="isAdmin" @click="toggleMode" class="bg-red-500 text-white px-4 py-2 rounded-lg">
          {{ isAnnouncement ? 'New Message' : 'Make an Announcement' }}
        </button>
        </div>
        <template v-if="!isChatting && !isAnnouncement">
        <div
          v-for="user in userList"
          :key="user.id"
          class="flex items-center justify-between p-4 border-b border-b-gray"
        >
          <div class="flex flex-row items-center">
            <img
              :src="
                user.avatar == null
                  ? 'https://ui-avatars.com/api/?name=' +
                    user.name.split(' ')[0] +
                    '+' +
                    user.name.split(' ')[1]
                  : user.avatar
              "
              alt="Avatar"
              class="w-8 h-8 rounded-full mr-4"
            />
            <div>
              <h1 class="text-md font-lighter">{{ user.name }}</h1>
            </div>
          </div>
          <button @click="startChatWith(user)" class="text-gray-400">
            <i class="fas fa-comment"></i>
          </button>
        </div>
      </template>

      <template v-if = "isChatting && !isAnnouncement">
        <!-- Chat Interface -->
        <textarea
          v-model="messageInput"
          class="border p-2 mb-4 w-full rounded-lg"
        ></textarea>
        <button
          @click="initiateConversation(selectedConversation)"
          class="bg-black text-white px-4 py-2 rounded-lg mr-2"
        >
          Send Message
        </button>
      </template>

      <template v-if="isAnnouncement"> 
        <!-- Chat Interface -->
        <div class="mb-4">
          <label for="announcementFilter" class="block text-sm font-medium text-gray-700">Filter by:</label>
          <select v-model="selectedFilter" id="announcementFilter" class="mt-1 block w-full p-2 border rounded-md">
            <option v-for="option in filterOptions" :key="option.value" :value="option.value">{{ option.label }}</option>
          </select>
        </div>
          <!-- Dropdown for Events, Delegations, etc. -->
          <div v-if="selectedFilter !== 'all'" class="mb-4">
          <label for="dynamicDropdown" class="block text-sm font-medium text-gray-700">Select:</label>
          <select v-model="selectedDynamicOption" id="dynamicDropdown" class="mt-1 block w-full p-2 border rounded-md">
            <option v-for="option in dynamicOptions" :key="option.value" :value="option.value">{{ option.label }}</option>
          </select>
        </div>
        <textarea
          v-model="announcementInput"
          class="border p-2 mb-4 w-full rounded-lg"
        ></textarea>
        <button
          @click="makeAnnouncement()"
          class="bg-black text-white px-4 py-2 rounded-lg mr-2"
        >
          Make announcement
        </button>
      </template>

      <button
        @click="closeModal"
        class="bg-red-500 text-white px-4 py-2 mt-5 rounded-lg"
      >
        Close
      </button>
    </div>
  </div>
</template>

<script setup>
import { defineProps, defineEmits, ref, onMounted, computed, watch  } from "vue";
import store from "@/store";
import router from "@/router";
import axios from "axios";

const props = defineProps(["close"]);
const emits = defineEmits();

const isChatting = ref(false);
const messageInput = ref("");
const myUserId = localStorage.getItem("user_id") ?? null;
const selectedConversation = ref(null);
const isAnnouncement = ref(false);
const announcementInput = ref('');
const selectedFilter = ref("all");
const dynamicOptions = ref([]);
const selectedDynamicOption = ref(null);

const isAdmin = ref(false);

if((JSON.parse(localStorage.getItem('role'))).includes(1) || (JSON.parse(localStorage.getItem('role'))).includes(2) ){
  isAdmin.value = true;
}

const closeModal = () => {
  emits("close");
};
const toggleMode = () => {
  isAnnouncement.value = !isAnnouncement.value;
  isChatting.value = false;
};

const filterOptions = [
  { label: "All", value: "all" },
  { label: "Events", value: "event" },
  { label: "Delegations", value: "delegation" },
  { label: "Roles", value: "role" },
];

watch(selectedFilter, () => {
  // Fetch dynamic options based on the selected filter
  // You can use the computed property dynamicOptions for this purpose
  // Example: fetchDynamicOptions();
  if (selectedFilter.value != 'all'){
    fetchDynamicOptions();
  }
  
});

const fetchDynamicOptions = async () => {
  // Fetch options based on the selected filter from the backend
  // Modify the URL according to your backend API endpoint
  if (selectedFilter.value != 'role'){
    const apiUrl = `announcement/options/${selectedFilter.value}`;

    // Make a request to the backend to fetch options
    // Modify the following axios call based on your backend API
    // Replace it with your actual API call to get dynamic options
    const optionsResponse = await axios.get(apiUrl);

    // Assuming the API response is an array of objects with 'value' and 'label' properties
    dynamicOptions.value = optionsResponse.data;
  } else{
     dynamicOptions.value = [
        { label: "Admin", value: 1 },
        { label: "Staff", value: 2 },
        { label: 'HOD', value: 3 },
        { label: "Coach", value: 4 },
    ];
  }
  

};

 
// const handleAnnouncement = () =>{
//   isAnnouncement.value = true;
//   isChatting.value = false;
// };

const makeAnnouncement = async() => {
  const content = announcementInput.value;
  const formData = new FormData();
  formData.append('announcement_content', content); 
  formData.append('option',  selectedFilter.value);
  if (selectedFilter.value == 'all'){
    formData.append('option-value', 'all')
  } else {
    formData.append('option-value', selectedDynamicOption.value)
  }
  await store.dispatch("makeAnnouncement", formData);
  const storedName = localStorage.getItem('user_name');
    const storedUserId = localStorage.getItem('user_id');

      // Create log entry
      const logData = {
        event_type: 'Announcement',
        event_notes: `${storedName} made an announcement: '${content} '`,
        created_by: storedUserId,
        updated_by: storedUserId,
      };
      const logResponse = await axios.post('/create-log', logData);
      console.log(logResponse.data.message);
  emits("close");
}

const userList = computed(() => {
  return store.getters["messagableUserList"];
});

const startChatWith = (user) => {
  // console.log("Starting chat with:", user.name);
  isAnnouncement.value = false;
  isChatting.value = true;
  selectedConversation.value = user.id;
};

const initiateConversation = async () => {
  if (!messageInput.value) alert("blank message");
  try {
    // messageSending.value = true;
    const formData = new FormData();
    formData.append("sender_id", myUserId);
    formData.append("receiver_id", selectedConversation.value);
    formData.append("message", messageInput.value);
    const response = await axios.post("/chat/conversations/initiate", formData);
    if (response?.data?.conversation) {
      const initiatedConversation = response?.data?.conversation;
      const receiver_id = response?.data?.conversation.receiver_id;
      await store.dispatch("conversationInitiated", {
        receiver_id,
        initiatedConversation,
      });
    }

    emits("close");
    await store.dispatch("fetchConversations");
    isAnnouncement.value = false;
    isChatting.value = false;
  } catch (error) {
    console.error("Error sending message:", error);
  }
};

onMounted(async () => {
  if (localStorage.getItem("user_id") && localStorage.getItem("user_id") > 0) {
    await store.dispatch("fetchMessagableUserList");
  }
});
</script>
<style scoped lang="scss">
.cw-500 {
  width: 500px;
}
</style>